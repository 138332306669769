import React from 'react';
import { HomeView } from 'views/HomeView';
import useErrorsHandler from 'views/common/hooks/useErrorsHandler';
import { HydrationWrapper } from 'views/common/components';
import handleHomeSSR from 'views/HomeView/utils/handleHomeSSR';
import { useSelector } from 'react-redux';
import { checkIfUrlHasCorrectSegmentsTranslations } from 'lib/utils/checkIfUrlHasCorrectSegmentsTranslations';

const Home = (props) => {
  const {
    serverData, initWidgetData, resumableReservations, renderErrorPage,
  } = props;
  const { isCrawler } = useSelector((state) => state.globalData);
  const handleErrorResponse = useErrorsHandler();
  if (renderErrorPage) {
    handleErrorResponse({}, false);
  }
  if (isCrawler) {
    return (
      <HydrationWrapper>
        <HomeView
          serverData={serverData}
          initWidgetData={initWidgetData}
          resumableReservations={resumableReservations}
        />
      </HydrationWrapper>
    );
  }
  return (
    <HomeView
      serverData={serverData}
      initWidgetData={initWidgetData}
      resumableReservations={resumableReservations}
    />
  );
};
export async function getServerSideProps(ctx) {
  const homeSSR = await handleHomeSSR(ctx);
  const isCorrectSegemntTranslation = checkIfUrlHasCorrectSegmentsTranslations(
    ctx,
    homeSSR.props.hreflangData.canonical,
  );
  if (isCorrectSegemntTranslation.shouldRedirect) {
    return {
      redirect: {
        destination: isCorrectSegemntTranslation.redirectURL,
        permanent: true,
      },
    };
  }
  return homeSSR;
}
export default Home;
